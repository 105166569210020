<template>
  <div>
    <draggable
      :list="contactsFiltered"
      :animation="200"
      ghost-class="ghost-card"
      group="contactsList"
      class="board-col"
      @change="changeList"
      v-if="contactsFiltered"
    >
      <div
        v-for="(contact, index) in contactsFiltered"
        :key="index"
        class="mt-3 cursor-move"
        @click="toogleSidebar(contact)"
      >
        <div class="bg-white shadow rounded px-3 pt-3 pb-5 border border-white">
          <div class="flex justify-between">
            <p
              class="text-gray-700 font-semibold font-sans tracking-wide text-xl truncate"
              v-if="showField('name')"
            >
              {{ contact.name }}
            </p>
            <vx-tooltip
              v-if="showField('type')"
              color="primary"
              title="Tipo de contato"
              :text="contact.type"
            >
              <vs-avatar
                v-if="contact.profilePic !== ''"
                :src="contact.profilePic"
                class="rounded-full w-7 h-7"
                style="margin-top: -2px; margin-right: -2px"
              />
              <vs-avatar
                v-else
                :text="contactNameInitials(contact.name)"
                class="rounded-full w-7 h-7"
                style="margin-top: -2px; margin-right: -2px"
              />
            </vx-tooltip>
          </div>
          <div class="flex justify-between items-center">
            <ul>
              <li v-if="showField('phone')">
                <small>
                  <a href="#">{{ contact.phone }}</a>
                </small>
              </li>
              <li v-if="showField('email')">
                <small>
                  <a href="#">{{ contact.email }}</a>
                </small>
              </li>
            </ul>
          </div>
          <div
            :class="{ hidden: !showField('tags') }"
            class="flex flex-wrap mt-2 w-full"
          >
            <vs-chip
              v-for="(tag, index) in contact.tags"
              :key="index"
              class="z-0"
            >
              {{
                `${tag.label}: ${tag.value}`.length > 36
                  ? `${tag.label}: ${tag.value}`.slice(0, 36)
                  : `${tag.label}: ${tag.value}`
              }}
              {{ `${tag.label}: ${tag.value}`.length > 36 ? "..." : "" }}
            </vs-chip>
          </div>
          <div class="flex mt-4 justify-between items-center">
            <vx-tooltip
              v-if="showField('date')"
              color="primary"
              title="Criado em"
            >
              <span v-if="showField('date')" class="text-sm text-gray-600">
                {{ contact.createdAt }}
              </span>
            </vx-tooltip>
          </div>
        </div>
      </div>
    </draggable>

    <div
      slot="no-results"
      class="mt-4 opacity-50 text-center"
      v-if="showNoResults"
    >
      <small>0 resultados em {{ phaseContact }}</small>
    </div>
    <div
      slot="no-more"
      class="mt-4 opacity-50 text-center"
      v-else-if="showEndResults"
    >
      <small>Todos os {{ phaseContact }} exibidos!</small>
    </div>
    <div slot="show-more" class="mt-4 opacity-50 text-center" v-else>
      <small>Carregar mais em {{ phaseContact }}</small>
    </div>
    <div class="text-center">
      <svg
        class="animate-spin"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        v-if="showLoading"
      >
        <path d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10" />
      </svg>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import normalizeString from "@/helpers/normalizeString";

export default {
  name: "CellRendererBoard",
  components: {
    draggable,
  },
  props: {
    phaseContact: {
      type: String,
      required: true,
    },
    typeContact: {
      type: String,
      required: true,
    },
    tagsContact: {
      type: String,
      required: true,
    },
    fieldsContact: {
      type: Array,
      required: true,
    },
    visiblePhases: {
      type: Array,
      required: true,
    },
    emailOrPhoneFilter: {
      type: String,
    },
    nameFilter: {
      type: String,
    },
    monthFilter: {
      type: Number,
    }
  },
  data() {
    return {
      page: 1,
      limit: 10,
      infiniteId: 1,
      count: 0,
      first: true,
      firstBatch: true,
      showLoading: true,
      showNoResults: false,
      showEndResults: false,
      requestOnGoing: false,
      contacts: [],
    };
  },
  computed: {
    contactsPhase() {
      return this.$store.state.contact.contactsPhase;
    },
    contactsFiltered() {
      if (this.contacts.length > 0) {
        return this.contacts.filter((el) => {
            return el.phase === this.phaseContact;
        });
      } else {
        this.$emit("setShowLoadMoreContacts", false, this.phaseContact);
        return [];
      }
    },
  },
  watch: {
    contactsPhase() {
      this.contacts = JSON.parse(JSON.stringify(this.contactsPhase));
    },
  },
  methods: {
    getContacts(type, monthFilter, currentYear) {
      const self = this;
      this.showLoading = true;
      if (this.visiblePhases.indexOf(this.phaseContact) > -1) {
        this.requestOnGoing = true;
        this.$store.dispatch("contact/getContactsBoard", {
          acc: this.$store.state.acc.current_acc.id,
          limit: this.limit,
          page: this.page,
          phase: this.phaseContact,
          search: normalizeString(this.nameFilter),
          tags: this.tagsContact.length > 0 ? this.tagsContact : null,
          monthFilter,
          currentYear,
        }).then((response) => {
          this.contacts = JSON.parse(JSON.stringify(this.contactsPhase));
          this.requestOnGoing = false;

          if (response.count > this.contactsFiltered.length)
            this.$emit("setShowLoadMoreContacts", true, this.phaseContact);
          else
            this.$emit("setShowLoadMoreContacts", false, this.phaseContact);

          this.$emit("setLoadMoreContactsLoading", false);
          this.count = response.count;
          this.$emit("updateCountBoard", {
            phase: this.phaseContact,
            count: response.count,
          });

          if (response.count > 0) {
            this.showLoading = false;
            this.firstBatch = false;
            if (response.count < this.limit) this.showEndResults = true;
            self.updateScroll();
            setTimeout(function () {
              self.updateScroll();
            }, 2000);
          } else {
            this.showLoading = false;
            if (this.count === 0) {
              this.showNoResults = true;
              setTimeout(function () {
                self.updateScroll(true);
              }, 2001);
            } else this.showEndResults = true;
          }
        });
      }
    },
    changeList(contact) {
      if (contact.removed) {
        let contactToUpdate = { ...contact.removed.element };
        contactToUpdate.phase = this.phaseContact;
        this.count--;
        this.$emit("updateCountBoard", {
          count: this.count,
          phase: this.phaseContact,
        });
        this.$store.dispatch("contact/updateContactBefore", contactToUpdate);
      }
      if (contact.added) {
        let contactToUpdate = { ...contact.added.element };
        contactToUpdate.phase = this.phaseContact;

        this.count++;
        this.$emit("updateCountBoard", {
          count: this.count,
          phase: this.phaseContact,
        });
        this.$store.dispatch("contact/updateContactAfter", contactToUpdate);
        var index = this.contacts.findIndex(
          (el) => el._id === contactToUpdate._id
        );
        if (index > -1) this.contacts[index].phase = this.phaseContact;
      }
    },
    toogleSidebar(contact) {
      this.$emit("sidebar", contact);
    },
    contactNameInitials(name) {
      var initials = name ? name.match(/\b\w/g) : [];
      return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    },
    showField(field) {
      return this.fieldsContact.includes(field);
    },
    updateScroll(reset = false) {
      const obj = document.querySelectorAll(".board-col");
      const objFather = document.getElementById("board-card");
      var graterW = `${objFather.scrollHeight}px`;
      for (var i = 0; i < obj.length; i++)
        if (!reset) obj[i].style.height = graterW;
        else {
          obj[i].style.height = "auto";
          obj[i].style.minHeight = "150px";
        }
    },
    loadMoreContacts: async function () {
      if (!this.showEndResults && !this.showNoResults) {
        const objHeader = document.getElementById("board-header");
        const obj = document.getElementById("board-card");
        objHeader.style = `left: -${obj.scrollLeft}px`;
        this.page++;
        this.getContacts(null, this.monthFilter);
      } else {
        setTimeout(() => {
          this.$emit("setLoadMoreContactsLoading", false);
        }, 2000);
      }
    },
    resetSearch(type, monthFilter, currentYear) {
      this.updateScroll(true);
      this.showNoResults = false;
      this.showEndResults = false;
      this.page = 1;
      this.showLoading = true;
      this.requestOnGoing = false;
      this.getContacts(type, monthFilter, currentYear);
    },
  },
  mounted() {
    if (this.count === 0) this.getContacts(null, this.monthFilter);
  },
};
</script>

<style lang="scss">
.width-board {
  max-width: 250px;
}
#card-board > div > .vx-card__body {
  padding: 1rem 0.75rem 1rem 0.75rem !important;
}
.overflow-name {
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
</style>
